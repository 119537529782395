$verdeIst: #6ab9af;
$verdeScuro: #539497;
$verdeChiaro: #e0edeb;

$grigioScuro: #5a5a5a;
$grigioMedioA: #9a9d9f;
$grigioMedioB: #caccce;
$grigioChiaro: #f0f1f3;

.app-footer {
    border-top: none;
}

.brand-wrapper {
    text-align: center;
    padding-top: 15px;
    height: 80px;
}

.side-footer {
    position: fixed;
    bottom: 0;
}

.ant-menu-dark {
    letter-spacing: 0.5px;

    ul {
        box-shadow: none !important;
    }

    .ant-menu-item {
        color: $grigioScuro !important;
        margin: 0px;
        padding: 0px;
        padding-bottom: 0px !important;
        padding-top: 0px !important;
        margin: 0px !important;

        .anticon {
            font-size: 18px;
            margin-right: 15px;
        }
    }

    .ant-menu-item:hover {
        background: $verdeChiaro;

        a:hover {
            color: $grigioScuro !important;
        }
    }

    .ant-menu-item-selected:hover {
        background: $grigioScuro;
    }

    .ant-menu-submenu-title {
        color: $grigioScuro !important;

        .anticon {
            font-size: 18px;
            margin-right: 15px;
        }
    }
}

// Ant button
.ant-btn {
    font-size: 12px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.ant-btn-primary:enabled:hover {
    background-color: $verdeScuro !important;
}

// Ant Card
.ant-card-head-title {
    letter-spacing: 1px;
    color: $grigioScuro;
}

.ant-card {
    letter-spacing: 1px !important;
    color: $grigioMedioA !important;
}

.ant-card-toolbar .ant-card-body {
    padding-bottom: 6px !important;
    padding-top: 6px !important;
}

// Ant description
.ant-descriptions-item-label {
    font-weight: 500 !important;
}

// Ant drawer
.ant-drawer-content {
    background-color: $grigioChiaro;
}

// Ant list
.ant-list-item {
    padding-left: 5px;
}

.ant-list-item:hover {
    background-color: $verdeChiaro;
}

.ant-list-item:last-child {
    border-bottom: hidden !important;
}

.ant-list-item-meta-title {
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    line-height: 18px;
    color: $grigioScuro
}

.ant-list-item-meta-description {
    letter-spacing: 1px;
    line-height: 18px;
    color: $grigioMedioA;
}

.ant-list-item.card-item {
    padding-left: 0px;
}

.ant-list-item.card-item:hover {
    background-color: transparent;
}

// Ant modal
.ant-modal-footer {
    border-top: none;
}

// Ant Page Header
.ant-page-header {
    background: white;
}

div.ant-page-header-back-button {
    font-size: 16px;
    color: rgba(0,0,0,.65);
}

span.ant-page-header-heading-title {
    letter-spacing: 0.5px;
    font-weight: 500;
    color: $verdeIst;
}

// Ant pagination
.ant-pagination-item {
    border: none;
}

// Ant selection
.ant-select-selection__choice {
    border: none !important;
}

// Ant table
.ant-table-placeholder {
    border: none !important;
}

.ant-table-thead > tr > th {
    color: $grigioScuro !important;
    background: $grigioChiaro !important;
    border-bottom: none !important;
    font-size: 15px !important;
}

.ant-table-tbody td a {
    color: $grigioScuro;
}

.ant-table-tbody td a:hover {
    color: $verdeIst;
}

// Ant upload
.ant-upload {
    background-color: $grigioChiaro !important;
    border: none !important;
}

.ant-upload:hover {
    background-color: $verdeChiaro !important;
}
.ant-descriptions-row > th, .ant-descriptions-row > td {
    padding-bottom: 3px;
}
.ant-descriptions-item-content {
    padding-bottom: 20px;
}
