.app-sidebar {
    .sidebar-header {
        transition: $l_transition_antd;
    }
    // React logo, #67DAF9
    .logo-img {
        width: 24px;
        height: 24px;
        // .st0 { fill: #fff; }
        .st1 {
            opacity: .9;
        }
    }

    .logo-react {
        .react-dot,
        .react-curve {
            opacity: .9;
        }

        .react-dot {
            fill: #fff;
            transition: $l_transition_antd;
        }

        .react-curve {
            stroke: #fff;
            transition: $l_transition_antd;
        }
    }

    .sidebar-footer {
        background-color: rgba(0,0,0, .035);
        // same with antd menu item
        > a {
            display: block;
            padding: 0 16px 0 24px;
            line-height: $sidebar_footer_height;

            &:focus {
                text-decoration: none;
            }

            .anticon {
                margin-right: 8px;
            }
        }
    }
}



.app-sidebar {
  .sidebar-header {
    padding: 0 0 0 19px; // padding-left(24px) + width/2 of AntIcon(14px/2) - width/2 of logo(24px/2) = 19
    line-height: $l_header_height;
  }

  .logo-img {
    float: left; // Style: Make logo out of flow so that when it won’t jump when Sider collapses and .brand text `display: none;`
    margin-top: 18px;
  }

  .brand {
    font-size: 20px;
    margin-left: 5px;
  }

  .brand_small{
    display: none;
  }

  // when antd Sider collapsed
  &.ant-layout-sider-collapsed {
    .sidebar-header {
      padding-left: 19px;
    }

    .logo-img {
      margin-bottom: -6px;
    }

    .brand {
      display: none;
    }

    .brand_small{
      display: inline;
    }
  }
}

