.bg-color-warning,
.bg-color-light {
  > .logo-react {
    .react-dot {
      fill: $body-color;
    }
    .react-curve {
      stroke: $body-color;
    }
  }
}

.sidebar-bg-light {
  background-color: $white;

  .sidebar-footer {
    color: $body-color;
    a {
      color: $body-color;
      &:hover {
        color: $body-color;
      }
    }
  }
}
.sidebar-bg-dark {
  background-color: $dark;
  .sidebar-footer {
    color: $white;
    opacity: .85;
    a {
      color: $white;
      opacity: .85;
      &:hover {
        color: $white;
        opacity: 1;
      }
    }
  }
}