
// custom style prefix with 'antd-' instead of official 'ant-'

// Base
.app-content {
  font-size: 14px;
}


// Type
.list-styled {
  ul { 
    list-style-type: disc; 
    list-style-position: inside; 
  }
  ol { 
    list-style-type: decimal; 
    list-style-position: inside; 
  }
  ul ul, ol ul { 
    list-style-type: circle; 
    list-style-position: inside; 
  }
  ul, ol {
    padding: 0 0 0 40px;
  }
}

// Card
.ant-card-head-title {
  margin: 0;
}


// Badge
.ant-scroll-number-only > p {
  margin-bottom: 0;
}

// Tag
.ant-tag-type-primary,
.ant-tag-type-success,
.ant-tag-type-info,
.ant-tag-type-warning,
.ant-tag-type-danger {
  font-size: 10px;
}
.ant-tag-type-primary {
  background-color: $brand-primary;
}
.ant-tag-type-success {
  background-color: $brand-success;
}
.ant-tag-type-info {
  background-color: $brand-info;
}
.ant-tag-type-warning {
  background-color: $brand-warning;
}
.ant-tag-type-danger {
  background-color: $brand-danger;
}

// Progress
.ant-progress-type-primary,
.ant-progress-type-success,
.ant-progress-type-info,
.ant-progress-type-warning,
.ant-progress-type-danger {
  .ant-progress-bg {
    opacity: .85;
  }
}
.ant-progress-type-primary {
  .ant-progress-bg {
    background-color: $brand-primary;
  }
}
.ant-progress-type-success {
  .ant-progress-bg {
    background-color: $brand-success;
  }
}
.ant-progress-type-info {
  .ant-progress-bg {
    background-color: $brand-info;
  }
}
.ant-progress-type-warning {
  .ant-progress-bg {
    background-color: $brand-warning;
  }
}
.ant-progress-type-danger {
  .ant-progress-bg {
    background-color: $brand-danger;
  }
}

// Forms
// Comment out Bootstrap forms
.form-group {
  margin-bottom: 1rem;
}