@import "font";
@import "variables";

@import "vendors/antd/antd";
@import "vendors/react";
@import "vendors/rc-queue-anim";
@import "pages/demo";
@import "pages/auth";

@import "pages/error";

.ant-btn{
    margin-right: 8px;
}

.parent {
        height: 100%;
        width: 100%;
        background-position: center;
        top: 0%;
        left: 0%;
        position: absolute;
        background-repeat: no-repeat;
        z-index: 10009 !important;
        opacity: 0.48;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=48)";
        filter: alpha(opacity=48);
        
        .spinner {
            padding-top: 25%;
            > * {margin: 0 auto;}
        }
}

.ant-form-item
{
    margin-bottom: 0px !important;
    .ant-input{
        font-size: 12px;
        height: 28px;
        margin: 0 8px 0px 0;
    
    }
}

.ant-card-toolbar {
    .ant-card-body 
    {
        padding-bottom: 12px !important;
        padding-top: 12px !important;
    }
}

.ant-card.card-full-height {
    height: 100%
}

.last-card {
    margin-bottom: 15px;
}

// Lista senza separatore
.ant-list-split {
    .ant-list-item.no-separator-list-item {
        border-bottom: 0;
    }
}

.ant-list-item.no-separator-list-item {
    padding: 5px 0;
}
