﻿// 100
@font-face {
    font-family: 'Sweet Sans Pro';
    font-style: normal;
    font-weight: 100;
    src: url('fonts/SweetSansPro-Thin.otf');
}

@font-face {
    font-family: 'Sweet Sans Pro';
    font-style: italic;
    font-weight: 100;
    src: url('fonts/SweetSansPro-ThinItalic.otf');
}

// 200
@font-face {
    font-family: 'Sweet Sans Pro';
    font-style: normal;
    font-weight: 200;
    src: url('fonts/SweetSansPro-ExLight.otf');
}

@font-face {
    font-family: 'Sweet Sans Pro';
    font-style: italic;
    font-weight: 200;
    src: url('fonts/SweetSansPro-ExLightItalic.otf');
}

// 300
@font-face {
    font-family: 'Sweet Sans Pro';
    font-style: normal;
    font-weight: 300;
    src: url('fonts/SweetSansPro-Light.otf');
}

@font-face {
    font-family: 'Sweet Sans Pro';
    font-style: italic;
    font-weight: 300;
    src: url('fonts/SweetSansPro-LightItalic.otf');
}

// Regular (400)
@font-face {
    font-family: 'Sweet Sans Pro';
    font-style: normal;
    src: url('fonts/SweetSansPro-Regular.otf');
}

@font-face {
    font-family: 'Sweet Sans Pro';
    font-style: italic;
    src: url('fonts/SweetSansPro-Italic.otf');
}

// 500
@font-face {
    font-family: 'Sweet Sans Pro';
    font-weight: 500;
    font-style: normal;
    src: url('fonts/SweetSansPro-Medium.otf');
}

@font-face {
    font-family: 'Sweet Sans Pro';
    font-weight: 500;
    font-style: italic;
    src: url('fonts/SweetSansPro-MediumItalic.otf');
}

// 700
@font-face {
    font-family: 'Sweet Sans Pro';
    font-style: normal;
    font-weight: 700;
    src: url('fonts/SweetSansPro-Bold.otf');
}

@font-face {
    font-family: 'Sweet Sans Pro';
    font-style: italic;
    font-weight: 700;
    src: url('fonts/SweetSansPro-BoldItalic.otf');
}

// 900
@font-face {
    font-family: 'Sweet Sans Pro';
    font-style: normal;
    font-weight: 900;
    src: url('fonts/SweetSansPro-Heavy.otf');
}

@font-face {
    font-family: 'Sweet Sans Pro';
    font-style: italic;
    font-weight: 900;
    src: url('fonts/SweetSansPro-HeavyItalic.otf');
}
