$font-size-small: 12px;

.body-auth {
  background-color: $body-bg;
}

.page-auth,
.page-login,
.page-forgot {
  .main-body {
    max-width: 480px;
  }
}

.page-signup, {
  .main-body {
    max-width: 520px;
  }
}

.page-auth,
.page-login,
.page-signup,
.page-forgot {
  padding: 0 10px;

  .main-body {
    margin: 0 auto;
    padding-top: 50px;
    min-width: 480px;
    @media (min-width: map-get($grid-breakpoints, md)) {
      padding-top: 150px;
    }
  }
  .body-inner {
    position: relative;
    padding: 15px 20px;
    &.no-additional-info {
      padding: 30px 20px;
    }
    &:before {
      z-index: 0;
      content: ' ';
      line-height: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      /*background-color: $brand-primary;*/
      height: 200px;
      border-radius: 0 0 $border-radius-lg $border-radius-lg;
    }

    .box {
      margin-bottom: 0;
    }
  }
  .help-info {
    color: $text-muted;
  }
  .additional-info {
    z-index: 1;
    position: relative;
    margin: 15px 0 0;
    text-align: center;
    font-size: $font-size-base;
    a {
      color: $bright;
    }
    .divider-h {
      border-right: 1px solid $bright;
      margin: 0 15px;
    }
  }

  .submit-button {
    width: 100%;
  }


  h1 {
    font-weight: normal;
    color: $brand-primary;
    font-size: 34px;
    margin-bottom: 40px;
    a {
      color: $brand-primary;
    }
  }

  .wrapper {
    margin-top: 50px;
    @media (min-width: map-get($grid-breakpoints, md)) {
      margin-top: 150px;
    }
  }

  .logo {
    font-size: 26px;
    font-weight: normal;
    margin-bottom: 12px;
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
}
