.divider {
  display: block;
  border: 0;
  border-top: 1px solid transparent;
  margin-top: 10px;
  margin-bottom: 10px; }
  .divider.divider-solid {
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1); }
  .divider.divider-dashed {
    border-style: dashed;
    border-color: rgba(0, 0, 0, 0.1); }
  .divider.divider-dotted {
    border-style: dotted;
    border-color: rgba(0, 0, 0, 0.1); }

.divider-xs {
  margin-top: 3px;
  margin-bottom: 3px; }

.divider-sm {
  margin-top: 5px;
  margin-bottom: 5px; }

.divider-md {
  margin-top: 15px;
  margin-bottom: 15px; }

.divider-lg {
  margin-top: 20px;
  margin-bottom: 20px; }

.divider-xl {
  margin-top: 30px;
  margin-bottom: 30px; }

.divider-xxl {
  margin-top: 50px;
  margin-bottom: 50px; }

.space {
  display: inline;
  padding: 6px; }

.space-md {
  padding: 15px; }

.space-lg {
  padding: 25px; }

span.block {
  display: block; }

.no-margin {
  margin: 0 !important; }

.no-margin-h {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.no-margin-top {
  margin-top: 0 !important; }

.no-margin-bottom {
  margin-bottom: 0 !important; }

.no-margin-left {
  margin-left: 0 !important; }

.no-margin-right {
  margin-right: 0 !important; }

.no-border {
  border: 0 !important; }

.no-border-radius {
  border-radius: 0; }

.no-padding {
  padding: 0 !important; }

.no-padding-h {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.no-shadow {
  box-shadow: 0; }

.full-width {
  width: 100%; }

.pull-in {
  margin-left: -15px;
  margin-right: -15px; }

.margin-b-lg {
  margin-bottom: 30px; }

.article-title,
.article-title-style {
  font-size: 24px;
  font-weight: normal;
  line-height: 32px;
  margin: .6em 0;
  color: #6ab9af; }
  .article-title.color-dark,
  .article-title-style.color-dark {
    color: rgba(0, 0, 0, 0.87); }

.chapter .article {
  padding-bottom: 30px; }
  .chapter .article:nth-of-type(1) .article-title {
    margin: 0 0 30px;
    border-top: none; }
  .chapter .article.article-dark {
    background-color: rgba(0, 0, 0, 0.015); }
  .chapter .article.article-bordered {
    border-top: 1px solid rgba(0, 0, 0, 0.117647);
    border-bottom: 1px solid rgba(0, 0, 0, 0.117647); }
    .chapter .article.article-bordered:last-child {
      border-bottom: 0; }
  .chapter .article.padding-lg-v {
    padding: 75px 0; }

.chapter .article-title {
  font-size: 24px;
  padding-top: 80px;
  font-weight: normal;
  line-height: 32px;
  margin: 48px 0 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.117647);
  color: #6ab9af; }

.hero {
  padding: 70px 0 75px;
  text-align: center; }

.hero-title {
  -webkit-font-smoothing: antialiased; }

.hero-title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 38px;
  font-weight: 300;
  line-height: 45px;
  margin-bottom: 10px; }

.hero-tagline {
  margin: 10px auto 24px;
  max-width: 700px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 300;
  font-size: 20px;
  line-height: 28px; }

.theme-gray .hero-title,
.theme-gray .hero-tagline,
.theme-dark .hero-title,
.theme-dark .hero-tagline {
  color: rgba(255, 255, 255, 0.7); }

.theme-gray .article-title.color-dark,
.theme-dark .article-title.color-dark {
  color: rgba(255, 255, 255, 0.7); }

.container-fluid {
  padding-left: 30px;
  padding-right: 30px; }
  .container-fluid.padding-lg-h {
    padding-left: 30px;
    padding-right: 30px; }
  .container-fluid.with-maxwidth {
    max-width: 1400px; }
  .container-fluid.with-maxwidth-lg {
    max-width: 960px; }
  .container-fluid.with-maxwidth-md {
    max-width: 960px; }
  .container-fluid.with-maxwidth-sm {
    max-width: 720px; }
  .container-fluid.no-breadcrumb, .container-fluid.no-breadcrumbs {
    padding-top: 2em;
    padding-bottom: 1em; }

.gradient-text {
  font-size: 150px;
  font-weight: 300;
  color: #6ab9af;
  background: -webkit-linear-gradient(92deg, #fb83fa, #00aced);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.text-small {
  font-size: 12px; }

.text-normal {
  font-size: 0.75rem; }

.text-huge {
  font-size: 72px; }

.text-large {
  font-size: 50px; }

.text-thin {
  font-weight: 300; }

.size-h1 {
  font-size: 1.75rem; }

.size-h2 {
  font-size: 1.375rem; }

.size-h3 {
  font-size: 1.125rem; }

.size-h4 {
  font-size: 1rem; }

.text-ultralight {
  font-weight: 100; }

.bold {
  font-weight: 500; }

.color-primary,
a.color-primary {
  color: #6ab9af; }
  .color-primary:hover,
  a.color-primary:hover {
    color: #6ab9af; }

.color-success,
a.color-success {
  color: #3dbd7d; }
  .color-success:hover,
  a.color-success:hover {
    color: #3dbd7d; }

.color-info,
a.color-info {
  color: #01BCD4; }
  .color-info:hover,
  a.color-info:hover {
    color: #01BCD4; }

.color-info-alt,
a.color-info-alt {
  color: #948aec; }
  .color-info-alt:hover,
  a.color-info-alt:hover {
    color: #948aec; }

.color-warning,
a.color-warning {
  color: #ffce3d; }
  .color-warning:hover,
  a.color-warning:hover {
    color: #ffce3d; }

.color-danger,
a.color-danger {
  color: #f46e65; }
  .color-danger:hover,
  a.color-danger:hover {
    color: #f46e65; }

.color-dark,
a.color-dark {
  color: #fff; }
  .color-dark:hover,
  a.color-dark:hover {
    color: #fff; }

.color-white,
a.color-white {
  color: #fff; }
  .color-white:hover,
  a.color-white:hover {
    color: #fff; }

.color-gray-darker,
a.color-gray-darker {
  color: #222; }
  .color-gray-darker:hover,
  a.color-gray-darker:hover {
    color: #222; }

.color-gray-dark,
a.color-gray-dark {
  color: #292b2c; }
  .color-gray-dark:hover,
  a.color-gray-dark:hover {
    color: #292b2c; }

.color-gray,
a.color-gray {
  color: #464a4c; }
  .color-gray:hover,
  a.color-gray:hover {
    color: #464a4c; }

.color-gray-light,
a.color-gray-light {
  color: #636c72; }
  .color-gray-light:hover,
  a.color-gray-light:hover {
    color: #636c72; }

.color-gray-lighter,
a.color-gray-lighter {
  color: #eceeef; }
  .color-gray-lighter:hover,
  a.color-gray-lighter:hover {
    color: #eceeef; }

.dropcap,
.dropcap-square,
.dropcap-circle {
  display: block;
  float: left;
  font-weight: normal;
  margin-right: .25em;
  text-shadow: none; }

.dropcap {
  font-size: 2em; }

.dropcap-square,
.dropcap-circle {
  background-color: #eceeef;
  color: rgba(0, 0, 0, 0.87);
  width: 36px;
  text-align: center; }

.dropcap-square {
  border-radius: 0.25rem;
  font-size: 2.3em; }

.dropcap-circle {
  border-radius: 50%;
  font-size: 1.78em; }

.dropcap.colored {
  color: #6ab9af; }

.dropcap-square.colored,
.dropcap-circle.colored {
  background-color: #6ab9af;
  color: #fff; }

.ui-highlight {
  background-color: #fff;
  color: #fff;
  border-radius: 0.25rem;
  padding: 2px 5px; }
  .ui-highlight.colored {
    background-color: #6ab9af; }

a.btn-w-xs,
button.btn-w-xs {
  min-width: 80px; }

a.btn-w-sm,
button.btn-w-sm {
  min-width: 100px; }

a.btn-w-md,
button.btn-w-md {
  min-width: 135px; }

a.btn-w-lg,
button.btn-w-lg {
  min-width: 160px; }

a.btn-round,
button.btn-round {
  border-radius: 2em; }

a.btn-gap,
button.btn-gap {
  margin: 5px; }

a.btn-gap-h,
button.btn-gap-h {
  margin: 0 5px; }

a.btn-gap-v,
button.btn-gap-v {
  margin: 0 0 5px; }

.btn-icon {
  padding: 0;
  display: inline-block;
  text-align: center;
  border-radius: 0.25rem;
  height: 35px;
  width: 35px;
  line-height: 35px; }
  .btn-icon i {
    line-height: 35px; }
  .btn-icon.btn-icon-lined {
    line-height: 31px; }
    .btn-icon.btn-icon-lined i {
      line-height: 31px; }
    .btn-icon.btn-icon-lined.btn-icon-thin {
      line-height: 33px; }
      .btn-icon.btn-icon-lined.btn-icon-thin i {
        line-height: 33px; }

.btn-icon-round {
  border-radius: 50%; }

.btn-icon-sm {
  height: 30px;
  width: 30px;
  line-height: 30px; }
  .btn-icon-sm i {
    line-height: 30px; }
  .btn-icon-sm.btn-icon-lined {
    line-height: 26px; }
    .btn-icon-sm.btn-icon-lined i {
      line-height: 26px; }
    .btn-icon-sm.btn-icon-lined.btn-icon-thin {
      line-height: 28px; }
      .btn-icon-sm.btn-icon-lined.btn-icon-thin i {
        line-height: 28px; }

.btn-icon-md {
  height: 45px;
  width: 45px;
  line-height: 45px;
  font-size: 18px; }
  .btn-icon-md i {
    line-height: 45px; }
  .btn-icon-md.btn-icon-lined {
    line-height: 41px; }
    .btn-icon-md.btn-icon-lined i {
      line-height: 41px; }
    .btn-icon-md.btn-icon-lined.btn-icon-thin {
      line-height: 43px; }
      .btn-icon-md.btn-icon-lined.btn-icon-thin i {
        line-height: 43px; }

.btn-icon-lg {
  height: 65px;
  width: 65px;
  line-height: 65px;
  font-size: 28px; }
  .btn-icon-lg i {
    line-height: 65px; }
  .btn-icon-lg.btn-icon-lined {
    line-height: 61px; }
    .btn-icon-lg.btn-icon-lined i {
      line-height: 61px; }
    .btn-icon-lg.btn-icon-lined.btn-icon-thin {
      line-height: 63px; }
      .btn-icon-lg.btn-icon-lined.btn-icon-thin i {
        line-height: 63px; }

.btn-icon-lg-alt {
  height: 70px;
  width: 70px;
  line-height: 70px; }
  .btn-icon-lg-alt i {
    line-height: 70px; }
  .btn-icon-lg-alt.btn-icon-lined {
    line-height: 66px; }
    .btn-icon-lg-alt.btn-icon-lined i {
      line-height: 66px; }
    .btn-icon-lg-alt.btn-icon-lined.btn-icon-thin {
      line-height: 68px; }
      .btn-icon-lg-alt.btn-icon-lined.btn-icon-thin i {
        line-height: 68px; }

.btn-icon-xl {
  height: 80px;
  width: 80px;
  line-height: 80px; }
  .btn-icon-xl i {
    line-height: 80px; }
  .btn-icon-xl.btn-icon-lined {
    line-height: 76px; }
    .btn-icon-xl.btn-icon-lined i {
      line-height: 76px; }
    .btn-icon-xl.btn-icon-lined.btn-icon-thin {
      line-height: 78px; }
      .btn-icon-xl.btn-icon-lined.btn-icon-thin i {
        line-height: 78px; }

.btn-twitter {
  color: #fff;
  background-color: #00c7f7;
  border-color: #00c7f7; }
  .btn-twitter:hover, .btn-twitter:focus, .btn-twitter:active {
    color: #fff;
    text-decoration: none;
    background-color: #00a6ce;
    border-color: #0096ba; }

.btn-facebook {
  color: #fff;
  background-color: #335397;
  border-color: #335397; }
  .btn-facebook:hover, .btn-facebook:focus, .btn-facebook:active {
    color: #fff;
    text-decoration: none;
    background-color: #294279;
    border-color: #243a69; }

.btn-gplus,
.btn-google-plus {
  color: #fff;
  background-color: #dd4a38;
  border-color: #dd4a38; }
  .btn-gplus:hover, .btn-gplus:focus, .btn-gplus:active,
  .btn-google-plus:hover,
  .btn-google-plus:focus,
  .btn-google-plus:active {
    color: #fff;
    text-decoration: none;
    background-color: #ca3522;
    border-color: #b8301f; }

.btn-instagram {
  color: #fff;
  background-color: #82685A;
  border-color: #82685A; }
  .btn-instagram:hover, .btn-instagram:focus, .btn-instagram:active {
    color: #fff;
    text-decoration: none;
    background-color: #6a5549;
    border-color: #5e4b41; }

.btn-vimeo {
  color: #fff;
  background-color: #63879C;
  border-color: #63879C; }
  .btn-vimeo:hover, .btn-vimeo:focus, .btn-vimeo:active {
    color: #fff;
    text-decoration: none;
    background-color: #537183;
    border-color: #4b6777; }

.btn-flickr {
  color: #fff;
  background-color: #0061DB;
  border-color: #0061DB; }
  .btn-flickr:hover, .btn-flickr:focus, .btn-flickr:active {
    color: #fff;
    text-decoration: none;
    background-color: #004fb2;
    border-color: #00469e; }

.btn-github {
  color: #fff;
  background-color: #3B3B3B;
  border-color: #3B3B3B; }
  .btn-github:hover, .btn-github:focus, .btn-github:active {
    color: #fff;
    text-decoration: none;
    background-color: #272727;
    border-color: #1c1c1c; }

.btn-pinterest {
  color: #fff;
  background-color: #D73532;
  border-color: #D73532; }
  .btn-pinterest:hover, .btn-pinterest:focus, .btn-pinterest:active {
    color: #fff;
    text-decoration: none;
    background-color: #bc2725;
    border-color: #ab2421; }

.btn-tumblr {
  color: #fff;
  background-color: #586980;
  border-color: #586980; }
  .btn-tumblr:hover, .btn-tumblr:focus, .btn-tumblr:active {
    color: #fff;
    text-decoration: none;
    background-color: #475568;
    border-color: #3f4b5c; }

.btn-linkedin {
  color: #fff;
  background-color: #018FAF;
  border-color: #018FAF; }
  .btn-linkedin:hover, .btn-linkedin:focus, .btn-linkedin:active {
    color: #fff;
    text-decoration: none;
    background-color: #016e86;
    border-color: #015d72; }

.btn-dribbble {
  color: #fff;
  background-color: #EA73A0;
  border-color: #EA73A0; }
  .btn-dribbble:hover, .btn-dribbble:focus, .btn-dribbble:active {
    color: #fff;
    text-decoration: none;
    background-color: #e55088;
    border-color: #e23e7c; }

.btn-stumbleupon {
  color: #fff;
  background-color: #EA4B24;
  border-color: #EA4B24; }
  .btn-stumbleupon:hover, .btn-stumbleupon:focus, .btn-stumbleupon:active {
    color: #fff;
    text-decoration: none;
    background-color: #d13914;
    border-color: #bf3412; }

.btn-lastfm {
  color: #fff;
  background-color: #B80638;
  border-color: #B80638; }
  .btn-lastfm:hover, .btn-lastfm:focus, .btn-lastfm:active {
    color: #fff;
    text-decoration: none;
    background-color: #90052c;
    border-color: #7d0426; }

.btn-evernote {
  color: #fff;
  background-color: #3BAB27;
  border-color: #3BAB27; }
  .btn-evernote:hover, .btn-evernote:focus, .btn-evernote:active {
    color: #fff;
    text-decoration: none;
    background-color: #308a1f;
    border-color: #2a791c; }

.btn-skype {
  color: #fff;
  background-color: #00B0F6;
  border-color: #00B0F6; }
  .btn-skype:hover, .btn-skype:focus, .btn-skype:active {
    color: #fff;
    text-decoration: none;
    background-color: #0093cd;
    border-color: #0084b9; }

.btn-soundcloud {
  color: #fff;
  background-color: #0066FF;
  border-color: #0066FF; }
  .btn-soundcloud:hover, .btn-soundcloud:focus, .btn-soundcloud:active {
    color: #fff;
    text-decoration: none;
    background-color: #0056d6;
    border-color: #004ec2; }

.btn-behance {
  color: #fff;
  background-color: #B80638;
  border-color: #B80638; }
  .btn-behance:hover, .btn-behance:focus, .btn-behance:active {
    color: #fff;
    text-decoration: none;
    background-color: #90052c;
    border-color: #7d0426; }

.btn-rss {
  color: #fff;
  background-color: #F79638;
  border-color: #F79638; }
  .btn-rss:hover, .btn-rss:focus, .btn-rss:active {
    color: #fff;
    text-decoration: none;
    background-color: #f58111;
    border-color: #e87709; }

.btn-youtube {
  color: #fff;
  background-color: #CC181E;
  border-color: #CC181E; }
  .btn-youtube:hover, .btn-youtube:focus, .btn-youtube:active {
    color: #fff;
    text-decoration: none;
    background-color: #a71419;
    border-color: #951216; }

.theme-gray .btn-default,
.theme-gray .btn-secondary,
.theme-dark .btn-default,
.theme-dark .btn-secondary {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.7); }

@media only screen and (max-width: 800px) {
  .table-flip-scroll {
    /* sort out borders */ }
    .table-flip-scroll .cf:after {
      visibility: hidden;
      display: block;
      font-size: 0;
      content: " ";
      clear: both;
      height: 0; }
    .table-flip-scroll * html .cf {
      zoom: 1; }
    .table-flip-scroll *:first-child + html .cf {
      zoom: 1; }
    .table-flip-scroll table {
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0; }
    .table-flip-scroll th,
    .table-flip-scroll td {
      margin: 0;
      vertical-align: top; }
    .table-flip-scroll th {
      text-align: left; }
    .table-flip-scroll table {
      display: block;
      position: relative;
      width: 100%; }
    .table-flip-scroll thead {
      display: block;
      float: left; }
    .table-flip-scroll tbody {
      display: block;
      width: auto;
      position: relative;
      overflow-x: auto;
      white-space: nowrap; }
    .table-flip-scroll thead tr {
      display: block; }
    .table-flip-scroll .table > thead > tr > th:first-child {
      border-top: 1px solid #ddd; }
    .table-flip-scroll th {
      display: block;
      text-align: right; }
    .table-flip-scroll tbody tr {
      display: inline-block;
      vertical-align: top; }
    .table-flip-scroll td {
      display: block;
      min-height: 1.25em;
      text-align: left; }
    .table-flip-scroll th {
      border-bottom: 0;
      border-left: 0; }
    .table-flip-scroll td {
      border-left: 0;
      border-right: 0;
      border-bottom: 0; }
    .table-flip-scroll tbody tr {
      border-left: 1px solid #babcbf; }
    .table-flip-scroll th:last-child,
    .table-flip-scroll td:last-child {
      border-bottom: 1px solid #babcbf; } }

.card-box {
  min-height: 120px;
  padding: 25px;
  background-color: #fff;
  border-radius: 0.25rem;
  text-align: center; }
  .card-box p {
    margin: 0; }
  .card-box .box-icon {
    display: inline-block;
    width: 70px;
    height: 70px;
    line-height: 70px;
    vertical-align: middle; }
    .card-box .box-icon.rounded {
      border-radius: 50%; }
  .card-box .btn-icon,
  .card-box .btn-icon-lined {
    margin: 0 15px 0 0;
    font-size: 1.75rem; }
  .card-box .box-info {
    display: inline-block;
    vertical-align: top; }
  .card-box .box-num {
    font-size: 24px;
    line-height: 1;
    margin: 10px 0 5px; }

.box {
  position: relative;
  margin-bottom: 1.5rem;
  /*border: 1px solid $box_border_color;
  border-radius: $border-radius;*/ }
  .box.box-transparent {
    border: 0; }
  .box .box-dark {
    background-color: rgba(0, 0, 0, 0.03); }

.box-default {
  background-color: #fff; }

.box-header,
.box-heading {
  font-size: 0.75rem;
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid transparent;
  border-radius: -0.75rem -0.75rem 0 0;
  font-weight: 500; }

.box-toolbar {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem; }
  .box-toolbar a {
    color: #636c72; }

.box-divider {
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  margin: 0 1.25rem; }

.box-body {
  padding: 1.25rem; }
  .box-body::after {
    display: block;
    content: "";
    clear: both; }
  .box-body.padding-lg {
    padding: 30px 15px; }
  .box-body.padding-xl {
    padding: 60px 15px; }
  .box-body.padding-lg-h {
    padding: 15px 30px; }
  .box-body.padding-lg-v {
    padding: 30px 15px; }

.theme-gray .box-default {
  background-color: white; }

.theme-dark .box-default {
  background-color: white; }

.card-white {
  color: rgba(0, 0, 0, 0.87);
  background-color: #fff; }

a.item-card {
  display: block;
  text-decoration: none; }
  a.item-card:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); }

.item-card {
  position: relative;
  transition: 0.2s linear; }
  .item-card img {
    max-width: 100%;
    max-height: 100%; }
  .item-card .card__image {
    padding: 30px 15px;
    height: 300px;
    background-color: rgba(0, 0, 0, 0.035);
    text-align: center; }
  .item-card h1, .item-card h2, .item-card h3, .item-card h4, .item-card h5, .item-card h6 {
    margin: 0;
    color: rgba(0, 0, 0, 0.87);
    line-height: 1.5; }
  .item-card h6 {
    font-size: 10px;
    opacity: .5; }
  .item-card h4 {
    font-size: 16px; }
  .item-card .card__body {
    position: relative;
    padding: 30px 15px;
    margin-bottom: 30px; }
  .item-card .card__price {
    position: absolute;
    top: 30px;
    right: 15px; }
    .item-card .card__price span {
      display: block;
      color: #636c72; }
      .item-card .card__price span.type--strikethrough {
        opacity: .5;
        text-decoration: line-through; }
  .item-card.card__horizontal {
    position: relative;
    margin-bottom: 30px; }
    .item-card.card__horizontal .card__image {
      width: 50%; }
    .item-card.card__horizontal .card__body {
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      padding: 40px 30px; }
    .item-card.card__horizontal .card__price {
      top: 30px;
      right: 30px; }
    .item-card.card__horizontal .card__desc {
      margin: 0 25% 3em 0;
      opacity: .7; }

.theme-gray .card-white,
.theme-dark .card-white {
  color: rgba(255, 255, 255, 0.7); }
  .theme-gray .card-white h1, .theme-gray .card-white h2, .theme-gray .card-white h3, .theme-gray .card-white h4, .theme-gray .card-white h5, .theme-gray .card-white h6,
  .theme-dark .card-white h1,
  .theme-dark .card-white h2,
  .theme-dark .card-white h3,
  .theme-dark .card-white h4,
  .theme-dark .card-white h5,
  .theme-dark .card-white h6 {
    color: rgba(255, 255, 255, 0.7); }

.theme-gray .card-white {
  background-color: white; }

.theme-dark .card-white {
  background-color: white; }

.icon-box {
  margin-top: 30px;
  position: relative;
  padding: 0 0 0 76px; }
  .icon-box .ibox-icon {
    display: block;
    position: absolute;
    width: 60px;
    height: 60px;
    top: 0;
    left: 0; }
    .icon-box .ibox-icon a {
      text-decoration: none; }
    .icon-box .ibox-icon a,
    .icon-box .ibox-icon i,
    .icon-box .ibox-icon img {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      color: #FFF; }
    .icon-box .ibox-icon i,
    .icon-box .ibox-icon img {
      border-radius: 50%;
      background-color: #6ab9af; }
    .icon-box .ibox-icon i {
      font-style: normal;
      font-size: 24px;
      text-align: center;
      line-height: 60px; }
  .icon-box h3 {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0;
    color: rgba(0, 0, 0, 0.87); }
  .icon-box p {
    margin: 8px 0 0 0;
    color: #999; }
  .icon-box .before-heading {
    font-size: 14px; }

/* Icon Box - Icon Large
-----------------------------------------------------------------*/
.icon-box.ibox-lg {
  padding: 0 0 0 115px; }
  .icon-box.ibox-lg .ibox-icon {
    width: 90px;
    height: 90px; }
    .icon-box.ibox-lg .ibox-icon i {
      font-size: 32px;
      line-height: 90px; }

/* Icon Box - Icon Outline
-----------------------------------------------------------------*/
.icon-box.ibox-outline .ibox-icon {
  border: 1px solid #6ab9af;
  border-radius: 50%;
  padding: 3px; }
  .icon-box.ibox-outline .ibox-icon i {
    line-height: 56px; }

/* Icon Box - Icon Outline Large
-----------------------------------------------------------------*/
.icon-box.ibox-outline.ibox-lg .ibox-icon {
  padding: 4px; }
  .icon-box.ibox-outline.ibox-lg .ibox-icon i {
    line-height: 86px; }

/* Icon Box - Icon Rounded
-----------------------------------------------------------------*/
.icon-box.ibox-rounded .ibox-icon {
  border-radius: 0.25rem !important; }
  .icon-box.ibox-rounded .ibox-icon i,
  .icon-box.ibox-rounded .ibox-icon img {
    border-radius: 0.25rem !important; }

/* Icon Box - Icon Rounded & Large
-----------------------------------------------------------------*/
.icon-box.ibox-rounded.ibox-lg .ibox-icon {
  border-radius: 0.25rem !important; }
  .icon-box.ibox-rounded.ibox-lg .ibox-icon i,
  .icon-box.ibox-rounded.ibox-lg .ibox-icon img {
    border-radius: 0.25rem !important; }

/* Icon Box - Light Background
-----------------------------------------------------------------*/
.icon-box.ibox-light i,
.icon-box.ibox-light img {
  background-color: #e5e5e5;
  color: rgba(0, 0, 0, 0.87); }

.icon-box.ibox-light.ibox-outline .ibox-icon {
  border-color: #E5E5E5; }
  .icon-box.ibox-light.ibox-outline .ibox-icon i {
    line-height: 54px; }

.icon-box.ibox-light.ibox-outline i,
.icon-box.ibox-light.ibox-outline img {
  border: 1px solid #E5E5E5;
  background-color: #e5e5e5;
  color: rgba(0, 0, 0, 0.87); }

.icon-box.ibox-light.ibox-outline.ibox-lg .ibox-icon i {
  line-height: 84px; }

.icon-box.ibox-light .ibox-icon i {
  line-height: 62px; }

.icon-box.ibox-lg .ibox-icon i {
  line-height: 96px; }

/* Icon Box - Dark Background
-----------------------------------------------------------------*/
.icon-box.ibox-dark.ibox-outline .ibox-icon {
  border-color: rgba(0, 0, 0, 0.87); }

.icon-box.ibox-dark .ibox-icon i,
.icon-box.ibox-dark .ibox-icon img {
  background-color: rgba(0, 0, 0, 0.87); }

/* Icon Box - Border
-----------------------------------------------------------------*/
.icon-box.ibox-border .ibox-icon {
  border: 1px solid #6ab9af;
  border-radius: 50%; }
  .icon-box.ibox-border .ibox-icon i,
  .icon-box.ibox-border .ibox-icon img {
    border: none;
    background-color: transparent !important;
    color: #6ab9af; }

.icon-box.ibox-border .ibox-icon {
  padding: 0; }
  .icon-box.ibox-border .ibox-icon i {
    line-height: 62px !important; }

.icon-box.ibox-border.ibox-lg .ibox-icon i {
  line-height: 94px !important; }

/* Icon Box - Border - Light
-----------------------------------------------------------------*/
.icon-box.ibox-border.ibox-light .ibox-icon {
  border-color: #E5E5E5; }

.icon-box.ibox-border.ibox-light i,
.icon-box.ibox-border.ibox-light img {
  color: #888; }

/* Icon Box - Border - Dark
-----------------------------------------------------------------*/
.icon-box.ibox-border.ibox-dark .ibox-icon {
  border-color: #333; }

.icon-box.ibox-border.ibox-dark i,
.icon-box.ibox-border.ibox-dark img {
  color: rgba(0, 0, 0, 0.87); }

/* Icon Box - Plain
-----------------------------------------------------------------*/
.icon-box.ibox-plain .ibox-icon {
  border: none !important;
  height: auto !important; }
  .icon-box.ibox-plain .ibox-icon i,
  .icon-box.ibox-plain .ibox-icon img {
    border: none !important;
    background-color: transparent !important;
    color: #6ab9af;
    border-radius: 0; }
  .icon-box.ibox-plain .ibox-icon i {
    font-size: 44px;
    line-height: 1 !important; }
  .icon-box.ibox-plain .ibox-icon img {
    width: 64px;
    height: 64px; }

.icon-box.ibox-plain.ibox-image .ibox-icon {
  width: auto; }

.icon-box.ibox-plain.ibox-image img {
  width: auto;
  display: inline-block; }

.icon-box.ibox-plain.ibox-small {
  padding-left: 42px; }
  .icon-box.ibox-plain.ibox-small .ibox-icon {
    width: 28px; }
    .icon-box.ibox-plain.ibox-small .ibox-icon i {
      font-size: 28px; }
    .icon-box.ibox-plain.ibox-small .ibox-icon img {
      height: 28px; }
  .icon-box.ibox-plain.ibox-small h3 {
    font-size: 15px;
    line-height: 26px;
    margin-bottom: 10px; }
  .icon-box.ibox-plain.ibox-small p {
    margin-left: -42px; }

.icon-box.ibox-plain.ibox-lg .ibox-icon i {
  font-size: 72px; }

.icon-box.ibox-plain.ibox-lg .ibox-icon img {
  height: 96px; }

.icon-box.ibox-plain.ibox-light .ibox-icon i,
.icon-box.ibox-plain.ibox-light .ibox-icon img {
  color: #888; }

.icon-box.ibox-plain.ibox-dark .ibox-icon i,
.icon-box.ibox-plain.ibox-dark .ibox-icon img {
  color: rgba(0, 0, 0, 0.87); }

/* Icon Box - Center
-----------------------------------------------------------------*/
.icon-box.ibox-center {
  padding: 0;
  text-align: center; }
  .icon-box.ibox-center.ibox-small {
    padding-left: 0 !important; }
  .icon-box.ibox-center .ibox-icon {
    position: relative;
    margin: 0 auto; }
  .icon-box.ibox-center h3 {
    margin: .8em 0 0; }
  .icon-box.ibox-center p {
    font-size: 14px;
    margin-top: 0em; }

/* Icon Box - Right
-----------------------------------------------------------------*/
.icon-box.ibox-right {
  padding: 0 80px 0 0;
  text-align: right; }
  .icon-box.ibox-right.ibox-lg {
    padding-right: 115px; }
  .icon-box.ibox-right .ibox-icon {
    left: auto;
    right: 0; }
  .icon-box.ibox-right.ibox-plain.ibox-small {
    padding: 0 42px 0 0; }
    .icon-box.ibox-right.ibox-plain.ibox-small p {
      margin: 0 -42px 0 0; }

/* Dark Theme
-----------------------------------------------------------------*/
.theme-gray .icon-box h3,
.theme-dark .icon-box h3 {
  color: rgba(255, 255, 255, 0.7); }

/* Boxed
-----------------------------------------------------------------*/
.box-body > .icon-box {
  margin-top: 0; }

img.img30_30 {
  width: 30px;
  height: 30px; }

img.img40_40 {
  width: 40px;
  height: 40px; }

img.img64_64 {
  width: 64px;
  height: 64px; }

img.img80_80 {
  width: 80px;
  height: 80px; }
