
.chapter {
  .ui-animate {
    > div {
      > .article {
        .article-title {
          margin: 48px 0 30px;
          border-top: 1px solid rgba(0, 0, 0, 0.117647);
        }
      }
    }
    > div:nth-of-type(1) {
      > .article {
        .article-title {
          margin: 0 0 30px;
          border-top: 0;
        }
      }
    }
  }
}
