.container-fluid {
  padding-left: 30px;
  padding-right: 30px;
  &.padding-lg-h {
    padding-left: $grid-gutter-width-base;
    padding-right: $grid-gutter-width-base;
  }
  &.with-maxwidth {
    max-width: $container_fluid_maxwidth;
  }
  &.with-maxwidth-lg {
    max-width: map-get($container-max-widths, lg);
  }
  &.with-maxwidth-md {
    max-width: map-get($container-max-widths, lg);
  }
  &.with-maxwidth-sm {
    max-width: map-get($container-max-widths, md);
  }
  &.no-breadcrumb,
  &.no-breadcrumbs {
    padding-top: 2em;
    padding-bottom: 1em;
  }
}