.app-header {
  &.bg-transparent {
    background-color: transparent !important;
  }
}

.app-header {
    .bg-color-light,
    .bg-color-dark,
    .bg-color-primary,
    .bg-color-success,
    .bg-color-info,
    .bg-color-warning,
    .bg-color-danger {
        .ant-menu-horizontal > .ant-menu-item:hover,
        .ant-menu-horizontal > .ant-menu-submenu:hover,
        .ant-menu-horizontal > .ant-menu-item-active,
        .ant-menu-horizontal > .ant-menu-submenu-active,
        .ant-menu-horizontal > .ant-menu-item-open,
        .ant-menu-horizontal > .ant-menu-submenu-open,
        .ant-menu-horizontal > .ant-menu-item-selected,
        .ant-menu-horizontal > .ant-menu-submenu-selected {
            border: 0;
        }

        .ant-menu-root {
            background-color: transparent;
        }
    }
}

.app-header {
  .bg-color-dark,
  .bg-color-primary,
  .bg-color-success,
  .bg-color-info,
  .bg-color-danger {
    .ant-menu-submenu-title {
      color: #fff;
    }
    .ant-menu-sub {
      color: rgba(0, 0, 0, 0.65);
      a {
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }

  .bg-color-light,
  .bg-color-warning {
    .ant-menu-submenu-title {
      color: $body-color;
    }
    .ant-menu-sub {
      color: $body-color;
      a {
        color: $body-color;
      }
    }
  }
}

