// = Just for demo =

// ui/typography
.typo-styles dt {
  display: block;
  float: left;
  color: #fff;
  background-color: rgba(0,0,0,.24);
  width: 32px;
  height: 32px;
  border-radius: 16px;
  line-height: 32px;
  text-align: center;
  font-weight: 500;
  margin-top: 5px;
}

.typo-styles dd {
  display: block;
  margin-left: 80px;
  margin-bottom: 20px;
}


.ant-color-container {

.color-palette {
  margin: 45px 0
}

.color-palette-pick {
  text-align: center;
  font-size: 20px;
  margin-bottom: 8px
}

.color-palette-picker {
  margin: 12px 0 24px
}

.color-palette-picker-value {
  font-size: 13px;
  font-family: Consolas
}

.main-color {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
}

.main-color-item {
  cursor: pointer;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 80px;
  border-radius: 4px;
  float: left;
  margin-right: 4px;
  -webkit-transition: all .2s;
  transition: all .2s;
  position: relative;
  text-align: center;
  padding-top: 28px;
  font-family: Consolas;
  font-size: 12px
}

.main-color-item:last-child {
  margin-right: 0
}

.main-color-item:hover {
  margin-top: -10px;
  height: 90px
}

.main-color-item .main-color-value {
  font-size: 12px;
  position: absolute;
  bottom: -4px;
  -webkit-transform: scale(.85);
  -ms-transform: scale(.85);
  transform: scale(.85);
  left: 0;
  width: 100%;
  text-align: center;
  opacity: 0;
  -webkit-transition: all .2s ease .1s;
  transition: all .2s ease .1s
}

.color-title {
  margin: 0 0 24px;
  font-weight: 500;
  color: #5c6b77;
  font-size: 26px;
  text-transform: capitalize;
  text-align: center
}

.color-description {
  font-size: 12px;
  font-weight: lighter;
  color: #777;
  display: block
}

.main-color:hover .main-color-item {
  padding-top: 4px
}

.main-color:hover .main-color-value {
  opacity: .7;
  bottom: 3px
}

.main-color .palatte-blue-1 {
  background: #ecf6fd
}

.main-color .palatte-blue-2 {
  background: #d2eafb
}

.main-color .palatte-blue-3 {
  background: #add8f7
}

.main-color .palatte-blue-4 {
  background: #7ec2f3
}

.main-color .palatte-blue-5 {
  background: #49a9ee
}

.main-color .palatte-blue-6 {
  background: #108ee9
}

.main-color .palatte-blue-7 {
  background: #0e77ca
}

.main-color .palatte-blue-8 {
  background: #0c60aa
}

.main-color .palatte-blue-9 {
  background: #09488a
}

.main-color .palatte-blue-10 {
  background: #073069
}

.main-color .palatte-purple-1 {
  background: #f4f3fd
}

.main-color .palatte-purple-2 {
  background: #e4e2fa
}

.main-color .palatte-purple-3 {
  background: #cfcaf6
}

.main-color .palatte-purple-4 {
  background: #b3acf2
}

.main-color .palatte-purple-5 {
  background: #948aec
}

.main-color .palatte-purple-6 {
  background: #7265e6
}

.main-color .palatte-purple-7 {
  background: #6252cd
}

.main-color .palatte-purple-8 {
  background: #533eb4
}

.main-color .palatte-purple-9 {
  background: #42299a
}

.main-color .palatte-purple-10 {
  background: #321580
}

.main-color .palatte-cyan-1 {
  background: #ebf8f9
}

.main-color .palatte-cyan-2 {
  background: #cfedf0
}

.main-color .palatte-cyan-3 {
  background: #a7dfe3
}

.main-color .palatte-cyan-4 {
  background: #76cdd3
}

.main-color .palatte-cyan-5 {
  background: #3db8c1
}

.main-color .palatte-cyan-6 {
  background: #00a2ae
}

.main-color .palatte-cyan-7 {
  background: #008997
}

.main-color .palatte-cyan-8 {
  background: #00707f
}

.main-color .palatte-cyan-9 {
  background: #005667
}

.main-color .palatte-cyan-10 {
  background: #003c4e
}

.main-color .palatte-green-1 {
  background: #ebf8f2
}

.main-color .palatte-green-2 {
  background: #cfefdf
}

.main-color .palatte-green-3 {
  background: #a7e1c4
}

.main-color .palatte-green-4 {
  background: #76d0a3
}

.main-color .palatte-green-5 {
  background: #3dbd7d
}

.main-color .palatte-green-6 {
  background: #00a854
}

.main-color .palatte-green-7 {
  background: #00924c
}

.main-color .palatte-green-8 {
  background: #007b43
}

.main-color .palatte-green-9 {
  background: #00643b
}

.main-color .palatte-green-10 {
  background: #004c32
}

.main-color .palatte-pink-1 {
  background: #feeff5
}

.main-color .palatte-pink-2 {
  background: #fdd8e7
}

.main-color .palatte-pink-3 {
  background: #fcb8d3
}

.main-color .palatte-pink-4 {
  background: #fa90ba
}

.main-color .palatte-pink-5 {
  background: #f7629e
}

.main-color .palatte-pink-6 {
  background: #f5317f
}

.main-color .palatte-pink-7 {
  background: #dc277d
}

.main-color .palatte-pink-8 {
  background: #c11c7b
}

.main-color .palatte-pink-9 {
  background: #a71278
}

.main-color .palatte-pink-10 {
  background: #8c0776
}

.main-color .palatte-red-1 {
  background: #fef0ef
}

.main-color .palatte-red-2 {
  background: #fcdbd9
}

.main-color .palatte-red-3 {
  background: #fabeb9
}

.main-color .palatte-red-4 {
  background: #f79992
}

.main-color .palatte-red-5 {
  background: #f46e65
}

.main-color .palatte-red-6 {
  background: #f04134
}

.main-color .palatte-red-7 {
  background: #d73435
}

.main-color .palatte-red-8 {
  background: #bd2636
}

.main-color .palatte-red-9 {
  background: #a31837
}

.main-color .palatte-red-10 {
  background: #880a38
}

.main-color .palatte-orange-1 {
  background: #fef3eb
}

.main-color .palatte-orange-2 {
  background: #fde3cf
}

.main-color .palatte-orange-3 {
  background: #fccca7
}

.main-color .palatte-orange-4 {
  background: #faaf76
}

.main-color .palatte-orange-5 {
  background: #f78e3d
}

.main-color .palatte-orange-6 {
  background: #f56a00
}

.main-color .palatte-orange-7 {
  background: #d75000
}

.main-color .palatte-orange-8 {
  background: #b93600
}

.main-color .palatte-orange-9 {
  background: #991b00
}

.main-color .palatte-orange-10 {
  background: #7a0000
}

.main-color .palatte-yellow-1 {
  background: #fffaeb
}

.main-color .palatte-yellow-2 {
  background: #fff3cf
}

.main-color .palatte-yellow-3 {
  background: #ffe9a7
}

.main-color .palatte-yellow-4 {
  background: #ffdd76
}

.main-color .palatte-yellow-5 {
  background: #ffce3d
}

.main-color .palatte-yellow-6 {
  background: #ffbf00
}

.main-color .palatte-yellow-7 {
  background: #e09a00
}

.main-color .palatte-yellow-8 {
  background: #c17500
}

.main-color .palatte-yellow-9 {
  background: #a04f00
}

.main-color .palatte-yellow-10 {
  background: #802800
}

.main-color .palatte-grey-1 {
  background: #fbfbfb
}

.main-color .palatte-grey-2 {
  background: #f7f7f7
}

.main-color .palatte-grey-3 {
  background: #f5f5f5
}

.main-color .palatte-grey-4 {
  background: #e9e9e9
}

.main-color .palatte-grey-5 {
  background: #d9d9d9
}

.main-color .palatte-grey-6 {
  background: #bfbfbf
}

.main-color .palatte-grey-7 {
  background: #919191
}

.main-color .palatte-grey-8 {
  background: #5a5a5a
}

.main-color .palatte-grey-9 {
  background: #404040
}

.main-color .palatte-grey-10 {
  background: #222
}

}

// ui/icons
.page-icons {
  ul.anticons-list {
    margin: 40px 0;
    list-style: none;
    overflow: hidden;
    background-color: #fff;
  }

  ul.anticons-list li {
    float: left;
    width: 16.66%;
    text-align: center;
    list-style: none;
    // cursor: pointer;
    height: 100px;
    color: #555;
    position: relative;
    margin: 3px 0;
    border-radius: 4px;
    // background-color: #fff;
    overflow: hidden;
    padding: 10px 0 0;
  }

  ul.anticons-list li:hover {
    background-color: #108ee9;
    color: #fff;
  }

  ul.anticons-list li .anticon {
    font-size: 24px;
    margin: 12px 0 16px;
    transition: all .3s;
  }

  ul.anticons-list li .anticon-class {
    display: block;
    text-align: center;
    transform: scale(.83);
    font-family: Lucida Console,Consolas;
    white-space: nowrap;
  }

}




// ui/grids
.page-grids .grid-structure .row {
  margin-top: .8rem;

  .widget-container {
    margin-top: 5px;
    background: rgba(#000, .1);
    padding: 10px 15px 12px;
    font-size: $font-size-sm;
    min-height: 0;
    border-radius: $border-radius;
  }

}
